<template>
	<div>
		<b-modal
			id="modal-add-banner"
			cancel-variant="outline-secondary"
			:ok-title="$t('Add')"
			:cancel-title="$t('Close')"
			ok-variant="primary"
			centered
			:title="$t('Add banner')"
			@ok="confirmAdd"
			@hidden="resetModal()"
		>
			<!-- form -->
			<validation-observer ref="addBannerModal">
				<b-form
					method="POST"
					class="auth-reset-password-form mt-2"
					@submit.prevent="validationForm"
				>
                    <!-- Field: Name -->
					<b-form-group
						label-for="title"
					>
						<label class="mb-1">{{ $t('Title') }}</label>
						<validation-provider
							#default="{ errors }"
							name="title"
						>
							<b-form-input
								id="title"
								v-model="banner.title"
								:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
							/>
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</b-form-group>

					<b-form-group
						label-for="type"
					>
						<label class="mb-1">{{ $t('Type') }}</label>
						<validation-provider
							#default="{ errors }"
							name="type"
						>
							<v-select
								v-model="banner.type"
								:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
								:options="typeOptions"
								:reduce="val => val.value"
							/>
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</b-form-group>

					<b-form-group
						label-for="platform"
					>
						<label class="mb-1">{{ $t('Platform') }}</label>
						<validation-provider
							#default="{ errors }"
							name="platform"
						>
							<v-select
								v-model="banner.platform"
								:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
								:options="platformOptions"
								:reduce="val => val.value"
							/>
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</b-form-group>

                    <b-col
						cols="12 px-0"
					>
                        <b-form-group
                            label-for="banners"
                        >
                            <label for=""><span class="text-danger">*</span> {{ $t('Image') }} <span class="text-secondary">(Không > 10Mb)</span></label>
                            <validation-provider
                                #default="{ errors }"
                                name="banners"
                                rules="required|size:10240"
                            >
                                <b-form-file
                                    v-model="banner.banners"
                                    multiple
                                    input-id="banners"
                                    accept="image/jpeg,image/png,image/gif,image/svg,image/jpg"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                            
                        </b-form-group>
                        <b-button
                            v-show="banner.banners.length != 0"
                            variant="outline-danger"
                            class="btn-sm btn-icon"
                            @click="banner.banners = []"
                        >
                            <feather-icon icon="XIcon"/>
                        </b-button>
                    </b-col>

					<!-- Field: Link -->
					<b-form-group
						label-for="link"
					>
						<label class="mb-1">{{ $t('Link') }}</label>
						<validation-provider
							#default="{ errors }"
							name="link"
						>
							<b-form-input
								id="link"
								v-model="banner.link"
								:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
							/>
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</b-form-group>
				</b-form>
			</validation-observer>

		</b-modal>
	</div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate/dist/vee-validate.full.esm'
import {
	BLink, BRow, BCol, BTabs, BTab, BForm, BFormGroup, BFormInput, BFormFile, BButton, BInputGroup, BInputGroupPrepend, BModal, VBModal, BInputGroupAppend, BFormTextarea, BBadge,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import router from '@/router'

export default {
	components: {
		BLink, BRow, BCol, BTabs, BTab, BForm, BFormGroup, BFormInput, BFormFile, BButton, BInputGroup, BInputGroupPrepend, BModal, BFormTextarea, BBadge,
		BInputGroupAppend,
		ValidationProvider,
		ValidationObserver,
		vSelect,
	},
	directives: {
		'b-modal': VBModal,
		Ripple,
	},
	props: {
	},
	data() {
		return {
			banner: {
                title: '',
				link: '',
                type: 1,
				platform: 1,
				banners: [],
			},
			typeOptions: [
				{
					label: 'Home',
					value: 1
				},
				{
					label: 'Promotion',
					value: 2
				}
			],
			platformOptions: [
				{
					label: 'Desktop',
					value: 1,
				},
				{
					label: 'Mobile',
					value: 2,
				}
			]
		}
	},
	methods: {
		validationForm() {
			this.$refs.addBannerModal.validate().then(success => {
				if (success) {
					this.addBanner(this.banner)
				}
			})
		},
		async addBanner(bannerData) {
			await this.$store.dispatch('frontend/addBanner', bannerData)
				.then(response => {
					if (response.status === 200) {
						if (response.data.code == '00') {
							this.$toast({
								component: ToastificationContent,
								props: {
									title: response.data.message || 'success',
									icon: 'CheckIcon',
									variant: 'success',
								},
							})
                            this.$emit('refetch-data')
							this.$nextTick(() => {
								setTimeout(() => {
									this.$bvModal.hide('modal-add-banner')
									this.resetModal()
								}, '1000')
							})
							this.$emit('refetch-data')
						} else {
							this.$toast({
								component: ToastificationContent,
								props: {
									title: response.data.message,
									icon: 'AlertTriangleIcon',
									variant: 'danger',
								},
							})
						}
					}
				})
		},
		resetModal() {
		},
		confirmAdd(bvModalEvt) {
			bvModalEvt.preventDefault()
			this.validationForm()
		}
	},
	setup() {

		return {
		}
	},
}
</script>