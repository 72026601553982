var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"id":"modal-add-banner","cancel-variant":"outline-secondary","ok-title":_vm.$t('Add'),"cancel-title":_vm.$t('Close'),"ok-variant":"primary","centered":"","title":_vm.$t('Add banner')},on:{"ok":_vm.confirmAdd,"hidden":function($event){return _vm.resetModal()}}},[_c('validation-observer',{ref:"addBannerModal"},[_c('b-form',{staticClass:"auth-reset-password-form mt-2",attrs:{"method":"POST"},on:{"submit":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label-for":"title"}},[_c('label',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.$t('Title')))]),_c('validation-provider',{attrs:{"name":"title"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"title","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.banner.title),callback:function ($$v) {_vm.$set(_vm.banner, "title", $$v)},expression:"banner.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-for":"type"}},[_c('label',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.$t('Type')))]),_c('validation-provider',{attrs:{"name":"type"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.typeOptions,"reduce":function (val) { return val.value; }},model:{value:(_vm.banner.type),callback:function ($$v) {_vm.$set(_vm.banner, "type", $$v)},expression:"banner.type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-for":"platform"}},[_c('label',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.$t('Platform')))]),_c('validation-provider',{attrs:{"name":"platform"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.platformOptions,"reduce":function (val) { return val.value; }},model:{value:(_vm.banner.platform),callback:function ($$v) {_vm.$set(_vm.banner, "platform", $$v)},expression:"banner.platform"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"cols":"12 px-0"}},[_c('b-form-group',{attrs:{"label-for":"banners"}},[_c('label',{attrs:{"for":""}},[_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('Image'))+" "),_c('span',{staticClass:"text-secondary"},[_vm._v("(Không > 10Mb)")])]),_c('validation-provider',{attrs:{"name":"banners","rules":"required|size:10240"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"multiple":"","input-id":"banners","accept":"image/jpeg,image/png,image/gif,image/svg,image/jpg"},model:{value:(_vm.banner.banners),callback:function ($$v) {_vm.$set(_vm.banner, "banners", $$v)},expression:"banner.banners"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.banner.banners.length != 0),expression:"banner.banners.length != 0"}],staticClass:"btn-sm btn-icon",attrs:{"variant":"outline-danger"},on:{"click":function($event){_vm.banner.banners = []}}},[_c('feather-icon',{attrs:{"icon":"XIcon"}})],1)],1),_c('b-form-group',{attrs:{"label-for":"link"}},[_c('label',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.$t('Link')))]),_c('validation-provider',{attrs:{"name":"link"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"link","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.banner.link),callback:function ($$v) {_vm.$set(_vm.banner, "link", $$v)},expression:"banner.link"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }